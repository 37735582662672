@import '../../../styles/customMediaQueries.css';

.root {
  margin-top: 10px;
  & svg {
    transform: scale(1.2);
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.0002em;
  text-align: left;
  color: #949494 !important;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #949494;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.0002em;
  text-align: left;
  text-decoration: underline;
  &:hover {
    color: var(--marketplaceColorDark);
    cursor: pointer;
  }
}
