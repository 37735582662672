@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  padding: 80px 40px 50px;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  @media (max-width: 1023px) {
    padding: 36px 20px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 18px;
  }
  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
  @media (max-width: 767px) {
    width: 100%;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);
  @media (max-width: 767px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 66px;
  @media (max-width: 767px) {
    margin-top: 48px;
  }
  & button {
    background-color: #fc690c;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 35px;
    height: 70px;
    @media (max-width: 767px) {
      height: 56px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.radioSignup {
  & label {
    & span {
      color: #595858;
      display: block;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      
      padding: 0;
      & svg{
        transform: scale(1.2);
      }
    }
  }
}

.radioBox {
  display: flex;
  gap: 35px;
  margin: 0px 0 22px;
  @media (max-width: 767px) {
    margin: 0px 0 16px;
  }
}
