@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  justify-content: flex-start;
  padding: 80px 40px 50px;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  @media (max-width: 1023px) {
    padding: 36px 20px;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 66px;
  @media (max-width: 767px) {
    margin-top: 48px;
  }
  & button {
    background-color: #fc690c;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 35px;
    height: 70px;
    @media (max-width: 767px) {
      height: 56px;
    }
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin: 10px 0 0;
  padding: 0;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: right;
  color: #D4D4D4;
  width: 100%;
  display: block;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;

}
